import { errorsService } from '@/main'
import client from '@/services/client'
import { errorsEnum } from '@/types/ErrorTypes'
import type { IUserRelations, IUserCategories } from '@/types/UserTypes'
import { isAxiosError } from 'axios'
import type { IUserForm } from '@/components/modals/AddUserModal.vue'
import type { IUserPermissionModule } from '@/types/PermissionsTypes'
import { userService } from '@/main'
import {
  SettingsUserAdd,
  SettingsUserBlock,
  SettingsUserRemove,
} from '@/types/PermissionsModules'
import type { GetNotificationSummary } from '@/types/TasksTypes'
import type { Nullable } from '@/types/Nullable'

interface IResetPasswordPayload {
  email: string
  token: string
  password: string
  password_confirmation: string
}

class UserRepository {
  public async getUsers(): Promise<IUserRelations[]> {
    return await client
      .get(`users`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUsers,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateActive(userId: number, active: boolean): Promise<void> {
    const activeStatus = active ? 1 : 0
    if (!userService.checkAccess(SettingsUserBlock)) return
    try {
      return await client.patch(`users/${userId}/active`, {
        activeStatus: activeStatus,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) return
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.UpdateActiveStatus,
          response: error.response,
        })
      }
      throw error
    }
  }

  public async delete(userId: number): Promise<void> {
    if (!userService.checkAccess(SettingsUserRemove)) return

    try {
      return await client.delete(`users/${userId}`)
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) return
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.DeleteUser,
          response: error.response,
        })
      }
      throw error
    }
  }

  public async getUsersCategories(
    query: URLSearchParams | string
  ): Promise<IUserCategories[]> {
    return await client
      .get(`tickets/identification/categories`, {
        params: query,
      })
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUsersCategories,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getUserPermissions(): Promise<IUserPermissionModule[]> {
    return await client
      .get<IUserPermissionModule[]>(`users/permissions`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUserPermissions,
            response: error.response,
            ignoreToast: true,
          })
        }

        throw error
      })
  }

  public async updateData(
    userId: number,
    phone: string,
    lastName?: string
  ): Promise<void> {
    try {
      return await client.patch(`users/profile/${userId}/settings`, {
        phone: phone,
        last_name: lastName,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) return
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.UpdateUserData,
          response: error.response,
        })
      }
      throw error
    }
  }

  public async resetPassword(payload: IResetPasswordPayload): Promise<void> {
    await client.post(`auths/password/reset`, payload).catch((error) => {
      if (isAxiosError(error) && error.response) {
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.ResetPassword,
          response: error.response,
        })
      }
      throw error
    })
  }

  public async sendResetPasswordEmail(email: string): Promise<void> {
    await client
      .post(`auths/password/link`, { email: email })
      .catch((error) => {
        if (isAxiosError(error) && error.response) {
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.SendResetPasswordEmail,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addUser(form: IUserForm): Promise<void> {
    if (!userService.checkAccess(SettingsUserAdd)) return

    await client.post(`users`, form).catch((error) => {
      if (isAxiosError(error) && error.response) {
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.AddUser,
          response: error.response,
        })
      }
      throw error
    })
  }

  public async getLog(id: number): Promise<unknown> {
    return await client
      .get(`logs/${id}/payload`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetUserLog,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getUserNotifications(): Promise<
    Nullable<GetNotificationSummary>
  > {
    return await client
      .get<GetNotificationSummary>(`assignments/notifications/summary`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return null
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetNotificationsSummary,
            response: error.response,
          })
        }

        throw error
      })
  }
}

export default new UserRepository()
