import type { ObjectKeys, ObjectValues } from '@/types/HelperTypes'
import type { AxiosResponse } from 'axios'

export const errorsEnum = {
  AddUser: 'addUser',
  GetUserPermissions: 'getUserPermissions',
  GetOneAttribute: 'getOneAttribute',
  GetManyAttributes: 'getAttributes',
  AddAttributes: 'addAttributes',
  UpdateAttributes: 'updateAttributes',
  AddRegistration: 'addRegistration',
  GetOneParameter: 'getOneParameter',
  GetManyParameters: 'getParameters',
  AddParameters: 'addParameters',
  UpdateParameters: 'updateParameters',
  Consents: 'consents',
  Login: 'login',
  Reauth: 'reauth',
  Manage2FA: 'manage2FA',
  Reset2FA: 'reset2FA',
  SendEmail: 'sendEmail',
  SendConfirmEmail: 'sendConfirmEmail',
  GetManyTasks: 'task',
  GetTaskFilters: 'task',
  GetTaskStatuses: 'taskStatuses',
  GetTaskTypes: 'taskTypes',
  GetNotifications: 'getNotifications',
  GetNotificationsSummary: 'getNotificationsSummary',
  DeleteNotificationSetting: 'deleteNotificationSetting',
  GetNotificationsTypes: 'getNotificationsTypes',
  GetManyRoles: 'getManyRoles',
  GetOneRole: 'getOneRole',
  GetUserRole: 'getUserRole',
  UpdateUserRoles: 'updateUserRole',
  GetRoleWithUsers: 'getRoleWithUsers',
  UpdateRole: 'updateRole',
  UpdateRolePermission: 'updateRolePermission',
  GetManyCompanies: 'getManyCompanies',
  GetOneCompany: 'getOneCompany',
  AddCompany: 'addCompany',
  UpdateCompany: 'updateCompany',
  AddCompanyUnit: 'addCompanyUnit',
  GetCompanyUnits: 'getCompanyUnits',
  GetUserUnits: 'getUserUnits',
  DeleteCompany: 'deleteCompany',
  AddCompanyUnitWithParent: 'addCompanyUnitWithParent',
  UpdateCompanyUnit: 'updateCompanyUnit',
  DeleteCompanyUnit: 'deleteCompanyUnit',
  GetConsents: 'getConsents',
  GetConsentsTypes: 'getConsentsTypes',
  GetManyConsents: 'getManyConsents',
  GetOneConsent: 'getOneConsent',
  GetManyConsentCompanies: 'getManyConsentCompanies',
  UpdateConsent: 'updateConsent',
  AddConsent: 'addConsent',
  DeleteConsent: 'deleteConsent',
  ApproveConsent: 'approveConsent',
  UploadFile: 'uploadFile',
  GetGlossaries: 'getGlossaries',
  GetAllPermissions: 'getAllPermissions',
  GetMultipleUsers: 'getMultipleUsers',
  GetOneUser: 'GetOneUser',
  GetUserLog: 'getUserLog',
  UpdateActiveStatus: 'updateActiveStatus',
  UpdateUserData: 'updateUserData',
  UpdateUserUnits: 'updateUserUnits',
  GetUserRoles: 'getUserRoles',
  GetUsers: 'getUsers',
  DeleteUser: 'deleteUser',
  GetUsersCategories: 'getUsersCategories',
  UpdateUserPermissions: 'updateUserPermissions',
  GetAssets: 'getAssets',
  GetAssetsByUserId: 'getAssetsByUserId',
  SaveAsset: 'saveAsset',
  SaveAssetCriticality: 'saveAssetCriticality',
  SaveAssetRisk: 'saveAssetRisk',
  GetNewRisk: 'getNewRisk',
  UpdatePassword: 'updatePassword',
  GetAttributeValues: 'getAttributeValues',
  AddTicketAttribute: 'addTicketAttribute',
  AssignCategory: 'assignCategory',
  AddIdentificationCategory: 'addIdentificationCategory',
  UpdateIdentificationCategory: 'updateIdentificationCategory',
  AddAffair: 'addAffair',
  GetAffair: 'getAffair',
  GetMultipleAffairs: 'getMultipleAffairs',
  UpdateAffair: 'updateAffair',
  ConnectAffair: 'connectAffair',
  DeleteAffair: 'deleteAffair',
  CreateTask: 'createTask',
  UpdateAction: 'updateAction',
  SendResetPasswordEmail: 'sendResetPasswordEmail',
  ResetPassword: 'resetPassword',
  GetTicketLogs: 'getTicketLogs',
  GetSource: 'getSource',
  AddSource: 'addSource',
  UpdateSource: 'updateSource',
  GetCaptcha: 'getCaptcha',
  VerifyCaptcha: 'postCaptcha',
  CreateAnonymousTicket: 'createAnonymousTicket',
  UpdateAnonymousTicket: 'updateAnonymousTicket',
  SendMessageAnonymous: 'sendAnonymousMessage',
  GetAnonymousTicket: 'getAnonymousTicket',
  AddNotificationSetting: 'addNotificationSetting',
  GetNotificationsSettings: 'getNotificationsSettings',
  GetNotificationSetting: 'getNotificationSetting',
  UpdateNotificationSetting: 'updateNotificationSetting',
  VerifyTicketPreview: 'verifyTicketPreview',
  GetMessages: 'getMessages',
  SendMessage: 'sendMessage',
  GetUserContext: 'getUserContext',
  GetStatuses: 'getStatuses',
  NotificationAsRead: 'notificationAsRead',
  NotificationDelete: 'notificationDelete',
  ReportsGet: 'reportsGet',
  ReportsDefinitionGet: 'reportsDefinitionGet',
  ReportsDefinitionFormatsGet: 'reportsDefinitionFormatsGet',
  ReportsAdd: 'reportsAdd',
  GetMimeTypes: 'getMimeTypes',
  GetAttachementConfig: 'getAttachementConfig',
  GetFilesByTypeAndId: 'getFilesByTypeAndId',
  GetFilesByIds: 'getFilesByIds',
  DeleteFile: 'deleteFile',
  CreateShareTicket: 'createShareTicket',
  GetSharedTickets: 'getSharedTickets',
  UpdateShareTicket: 'updateShareTicket',
  DeleteShareTicket: 'deleteShareTicket',
  GetUsersCompanyUnitRole: 'getUsersCompanyUnitRole',
  GetAvailableDelegates: 'getAvailableDelegates',
} as const

export type ErrorEnumType = ObjectKeys<typeof errorsEnum>
export type ErrorEnumVals = ObjectValues<typeof errorsEnum>

export interface IErrorsState {
  scopesErrors: IScopesErrors
  scopeFlowErrors: IScopesErrors
}

export interface IScopeErrorsPayload {
  scope: string
  response: AxiosResponse
  ignoreToast?: boolean
  message?: string
  statusCode?: number
}

export interface IScopeError {
  scope: string
  errors: IError
}

export interface IError {
  [key: string]: string[] | number | string | number[]
  statusCode: number
}

export interface IErrorElement {
  [key: string]: string | number
}

export interface IErrorTransformed {
  [key: string]: string
}

export interface IScopesErrors {
  [key: string]: IError
}

export interface ErrorElementsType {
  [key: string]: string
}
